import { AntDesignFilterFilled } from '@/assets/icons/funds/AntDesignFilterFilled';
import PullToRefresh from '@/components/base/basePullToRefresh';
import BaseSift from '@/components/base/baseSift';
import { useEffect, useMemo, useState } from 'react';
import useConstants from '@/pages/funds/orderHistory/index/useConstants';
import { useTranslation } from 'react-i18next';
import { Button, Divider, useDisclosure } from '@nextui-org/react';
import { FeaturesDateTime } from '@/components/features/featuresDateTime';
import { usePaginationPage } from '@/hooks/usePaginationPage';
import {  withdrawalListApi } from '@/services/api/requestApi';
import useUrlState from '@ahooksjs/use-url-state';
import FeaturesRechargeAndCashWithdrawalCard from '@/components/features/featuresRechargeAndCashWithdrawalCard';
import { history } from '@@/core/history';
import PageEnum from '@/enums/pageEnum';

export default function WithdrawRecordList({sendFn,setLoadingDisplay}:any) {
  // 基础定义
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [time, setTime] = useState<string[]>([]);
  const {
    statusOption,
  } = useConstants();
  const [currentStatus, setCurrentStatus] = useState({
    text: t('全部'),
    value: undefined,
  });
  // 请求参数(依赖更新,分页hooks会自动发起请求)
  const [pageParams, setPageParams] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  const [urlState] = useUrlState<any>({});
  const {
    walletType,
    coinId,
  } = urlState || {};
  const params = useMemo(() => {
    return {
      status: currentStatus?.value,
      walletType,
      coinId,
      startTime: time.length > 0 ? encodeURI(time[0] + 'T00:00:00') : undefined,
      endTime: time.length > 0 ? encodeURI(time[1] + 'T23:59:59') : undefined,
      ...pageParams
    };
  }, [ coinId, pageParams, walletType,currentStatus,time]);
  //提现列表
  const {
    list,
    loading,
    loadMore,
    error,
    total,
    ...withdrawalRecordsPageProps
  } = usePaginationPage({
    fn: withdrawalListApi,
    params,
    setParams:setPageParams
  });
      // 更新监听
      useEffect(()=>{
        if(setLoadingDisplay){
          setLoadingDisplay?.(loading)
        }
      },[loading])
  return (
    <div>
      <div className="flex justify-between relative">
        <BaseSift
          options={statusOption as any}
          onChange={setCurrentStatus as any}
          current={currentStatus}
          title={t('状态')}
        />

        <AntDesignFilterFilled
          className="text-iconFontColor sm:cursor-pointer text-base absolute right-0 cursor-pointer"
          onClick={onOpen}
        />
      </div>
      {/*列表*/}
      <div className="pt-[30px]">
        <PullToRefresh
          loadMore={loadMore}
          total={total}
          list={list}
          error={error}
          loading={loading}
          loadingDisplay={false}
          {...withdrawalRecordsPageProps}
        >
          {list.map((item: any) => {
            return (
              <div key={item?.id}>
                <FeaturesRechargeAndCashWithdrawalCard
                  item={item}
                  clickOnTheCard={(data) => {
                    history.push(PageEnum.TRADINGHISTORYDETAIL, {
                      id: data.id,
                      type: data?.cardType,
                    });
                  }}
                >
                  {
                    sendFn && <Button
                      className="mainColorButton !h-[30px] !py-0 mt-1"
                      onClick={() => {
                        sendFn({ type: item.cardType, id: item?.id });
                      }}
                    >
                      {t('发送')}
                    </Button>
                  }
                  <Divider className="my-4 !bg-backgroundAuxiliaryColor" />
                </FeaturesRechargeAndCashWithdrawalCard>
              </div>
            );
          })}
        </PullToRefresh>
      </div>
      {/*时间弹窗*/}
      <FeaturesDateTime
        open={isOpen}
        onClose={onClose}
        onCancel={onClose}
        onConfirm={(value: any) => {
          const { start, end } = value;
          setTime([start, end]);
          onClose();
        }}
      />
    </div>
  );
};

