import { SvgSpinnersBarsRotateFade } from '@/assets/icons/comm/SvgSpinnersBarsRotateFade';
import { ReactNode, TouchEvent, useRef, useState } from 'react';

/**
 * @component 下拉刷新组件
 * @param{()=>void} refreshFn - 刷新回调函数,必传
 * @param{ReactNode} children - 显示的列表必穿
 * @param{number} threshold - 出发刷新事件的阈值,可选,默认100px
 * @param{boolean} loading - 是否正在刷新，,可选（用于不想传异步函数，想传入loading的时候）,默认false
 * @param disabled
 */
function BasePullDownToRefresh({
                                 refreshFn,
                                 children,
                                 threshold = 100,
                                 loading = false,
                                 disabled = false,
                                 showLoading = true,
                                 classNames = {
                                   base: '',
                                   content: '',
                                 },
                                 className = '',
                               }: IProps) {
  //是否正在移动，用于是否回弹
  const [isMove, setIsMove] = useState(false);
  //鼠标落下的y坐标
  const [startY, setStartY] = useState(0);
  //当前y坐标
  const [currentY, setCurrentY] = useState(0);
  //获取当前容器的
  const containerRef = useRef<HTMLDivElement>(null);
  //鼠标落下事件
  const handleTouchStart = (event: TouchEvent) => {
    if (disabled) return;
    const { scrollTop } = document.documentElement;
    const isChildren =
      containerRef.current &&
      !containerRef.current.contains(event.target as Node);
    if (scrollTop <= 0 && !isChildren) {
      setStartY(event.touches[0].clientY);
    }
  };
  //鼠标移动事件
  const handleTouchMove = (event: TouchEvent) => {
    //判断当前触发事件是不是在children下；以防那种在body下面弹出层也可以下拉刷新
    const isChildren =
      containerRef.current &&
      !containerRef.current.contains(event.target as Node);
    const { scrollTop } = document.documentElement;
    if (scrollTop <= 0 && !isChildren && startY !== 0) {
      setCurrentY(event.touches[0].clientY);
      setIsMove(true);
    }
  };

  //鼠标放开事件
  const handleTouchEnd = async () => {
    //距离
    const distance = currentY - startY;
    //判断是否达到阈值调用刷新回调
    if (distance > threshold) {
      try {
        await refreshFn?.();
      } finally {
        //松开鼠标弹回
        setIsMove(false);
        //充值状态
        setCurrentY(0);
        setStartY(0);
      }
    } else {
      //松开鼠标弹回
      setCurrentY(0);
      setStartY(0);
      setIsMove(false);
    }
  };

  return (
    <div className={`overflow-hidden ${classNames?.base}`}>
      {/*判断是否是在移动中，如果没有就初始状态看不到刷新块区域，鼠标移动就移动具体的未知，loading跟着变大*/}
      <div
        className={`duration-150 ${classNames?.content}`}
        style={{
          transform: `translateY(${
            isMove || (loading && currentY !== 0)
              ? Math.min(currentY - startY - 100, 0)
              : -120
          }px)`,
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {showLoading && (
          <div
            className="text-center h-[120px] leading-[150px] flex justify-center items-center bg-backgroundAuxiliaryColor ">
            <SvgSpinnersBarsRotateFade className="text-2xl text-foreground" />
          </div>
        )}

        <div ref={containerRef} className={`${className}`}>{children}</div>
      </div>
    </div>
  );
}

interface IProps {
  // 刷新回调函数
  refreshFn: () => void;
  // 需要显示的元素
  children: ReactNode;
  // 触发刷新的阈值
  threshold?: number;
  // 是否正在刷新
  loading?: boolean;
  //是否禁用
  disabled?: boolean;
  //是否禁用 加载动画
  showLoading?: boolean;
  // 类名
  classNames?: {
    base?: string
    content?: string
  };
  className?: string;
}

export default BasePullDownToRefresh;
