import { IconParkSolidCorrect } from '@/assets/icons/comm/IconParkSolidCorrect';
import { TeenyiconsDownSolid } from '@/assets/icons/comm/TeenyiconsDownSolid';
import BaseModal from '@/components/base/baseModal';
import BaseTabs from '@/components/base/baseTabs';
import { FundsSourceEnum, WalletTypeEnum } from '@/enums/businessEnum';
import useConstants from '@/pages/funds/orderHistory/index/useConstants';
import { useModel } from '@@/exports';
import useUrlState from '@ahooksjs/use-url-state';
import { ScrollShadow, useDisclosure } from '@nextui-org/react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * 用于实现一个具有下拉菜单功能的过滤框。
 * @param props 组件接收的属性对象，包含以下字段：
 * - options: 提供给下拉菜单的选项数组，每个选项包含`text`和`value`属性。
 * - onChange: 当选项被选择时调用的回调函数，接收选中的值作为参数。
 * - defaultText: 默认显示在过滤框中的文本。
 * @returns 渲染的React元素，包括一个点击可打开下拉菜单的文本框和一个下拉菜单。
 */
export default function OrderHistorySift({ onChange, current, title }: IProps) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [isFirst, setIsFirst] = useState(true);

  const downMenuClick = (value: any) => {
    if (value?.text === current?.text) {
      onClose();
      return;
    }
    onChange(value);
    setIsFirst(false);
    onClose();
  };
  const { t } = useTranslation();

  const renderTitle = useMemo(() => {
    return isFirst && current?.value === undefined ? title : current?.text;
  }, [current]);

  // 取地址参数
  const [urlState] = useUrlState<any>({});
  const { walletType } = urlState || {};
  // 全部来源
  const { sourceOptions: allSource } = useConstants();
  // 根据账户不同筛选出的来源
  const sourceOptions = useMemo(() => {
    if (walletType === undefined) return allSource;
    if (walletType === WalletTypeEnum.CONTRACT) {
      return allSource?.filter((item: any) => {
        return item?.type === WalletTypeEnum.CONTRACT || item?.type === 'all';
      });
    }
    if (walletType === WalletTypeEnum.COIN) {
      return allSource?.filter((item: any) => {
        return item?.type === WalletTypeEnum.COIN || item?.type === 'all';
      });
    }
    if (walletType === WalletTypeEnum.FASTCONTRACT) {
      return allSource?.filter((item: any) => {
        return (
          item?.type === WalletTypeEnum.FASTCONTRACT || item?.type === 'all'
        );
      });
    }
    return [];
  }, [walletType]);

  // 分类
  const tabList = [
    {
      text: t('账户操作'),
      value: FundsSourceEnum.ACCOUNT_OPERATION,
    },
    {
      text: t('股票'),
      value: FundsSourceEnum.STOCK_OPERATION,
    },
    {
      text: t('合约'),
      value: FundsSourceEnum.CONTRACT_OPERATION,
    },
    {
      text: t('现货'),
      value: FundsSourceEnum.BINANCE_OPERATION,
    },
    {
      text: t('IEO'),
      value: FundsSourceEnum.SUBSCRIPTION,
    },
    {
      text: t('基金'),
      value: FundsSourceEnum.FUND_OPERATION,
    },
    // {
    //   text: t('邀请'),
    //   value: FundsSourceEnum.SYSTEM_INVITATION,
    // },
  ];
  // 渲染分类
  const renderTabs = useMemo(() => {
    return tabList.filter((item: any) => {
      // 确保是有满足分类的，分类下为空也没必要展示
      return sourceOptions.some((item2: any) => {
        return Number(item2.sourceClassification) === Number(item.value);
      });
    });
  }, [sourceOptions]);
  // 移动端当前分类下的列表
  const [classification, setClassification] = useState<any>();
  // 默认选中第一个tab分类
  // useUpdateEffect(() => {
  //   setClassification(renderTabs[0]?.value);
  // }, [renderTabs]);
  // 生成分类后的来源
  const renderSource = useMemo(() => {
    const res = sourceOptions.filter((item: any) => {
      return Number(item.sourceClassification) === Number(classification);
    });
    // onChange(res[0]);
    return res;
  }, [sourceOptions, classification]);
  return (
    <>
      <span
        className="filterBox rounded-md"
        onClick={() => {
          onOpen();
        }}
      >
        <div className="cursor-pointer">{renderTitle}</div>
        <TeenyiconsDownSolid className="ml-1" width="0.5em" height="0.8em" />
      </span>

      {/*移动端显示抽屉*/}
      <BaseModal
        isOpen={isOpen}
        onClose={onClose}
        classNames={{ title: 'text-[17px] font-semibold' }}
        title={title}
      >
        <div>
          {renderTabs?.length > 0 && (
            <div className="px-3">
              <BaseTabs
                options={renderTabs as any}
                onChange={setClassification as any}
                value={classification}
              />
            </div>
          )}
          <ScrollShadow hideScrollBar className="h-[40vh]">
            <div className="mt-2 ">
              {renderSource?.map((item) => (
                <div
                  key={item.value}
                  onClick={(e) => {
                    e.stopPropagation();
                    downMenuClick(item);
                  }}
                  className={`flex items-center sm:cursor-pointer px-4 justify-between text-foreground  border-t-[1px] border-backgroundAuxiliaryColor text-[16px]   py-4`}
                >
                  <span>{item.text}</span>
                  {current?.value === item.value && (
                    <IconParkSolidCorrect className="text-primary" />
                  )}
                </div>
              ))}
            </div>
          </ScrollShadow>

          <div
            onClick={onClose}
            className={`text-center sm:cursor-pointer  border-t-[8px] border-backgroundAuxiliaryColor  text-[16px] text-foreground  py-4 `}
          >
            <span>{t('取消')}</span>
          </div>
        </div>
      </BaseModal>
    </>
  );
}

// 定义组件接收的props接口
interface IProps {
  onChange: (value: { text: string; value?: any }) => void;
  current:
    | {
        text: string;
        value?: any;
      }
    | any;
  title: string;
}
