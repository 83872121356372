import {
  CompletedOrderOrderProcessEnum,
  CompletedOrderOrderStatusEnum,
} from '@/enums/businessEnum';
import RenderUtil from '@/utils/RenderUtil';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({
  item,
  children,
  clickOnTheCard,
  isRight = false,
  isReply = false,
}: IProps) => {
  const { t } = useTranslation();
  const successColor = 'text-successColor';
  const textColor = useMemo(() => {
    if (isRight && !isReply) {
      return 'text-backContrastColor';
    } else if (isReply) {
      return 'text-foreground';
    } else {
      return 'text-auxiliaryTextColor';
    }
  }, [isRight, isReply]);

  const showCardMap = {
    [+CompletedOrderOrderProcessEnum.ADD]: [
      // {
      //   label: t('币种'),
      //   value: <span className={successColor}>{item?.coinName}</span>,
      // },
      {
        label: t('进度'),
        value: (
          <>
            {item?.status === +CompletedOrderOrderStatusEnum.UnderReview && (
              <span className="text-left text-warningColor">{t('审核中')}</span>
            )}
            {item?.status ===
              +CompletedOrderOrderStatusEnum.WAITING_FOR_REVIEW && (
              <span className="text-left text-warningColor">
                {t('等待审核中')}
              </span>
            )}
            {item?.status === +CompletedOrderOrderStatusEnum.COMPLETED && (
              <span className={`text-left  ${successColor}`}>
                {t('已通过')}
              </span>
            )}
            {item?.status === +CompletedOrderOrderStatusEnum.DID_NOT_PASS && (
              <span className="text-left text-errorColor">{t('未通过')}</span>
            )}
          </>
        ),
      },
      {
        label: t('充值金额'),
        value: <span>{RenderUtil.FormatAmount(item?.amount, 8)}{' '+item?.quoteCoinName}</span>,
      },
      {
        label: t('到账金额'),
        value: <span>{RenderUtil.FormatAmount(item?.actualAmount, 8)}{' '+item?.coinName}</span>,
      },
      {
        label: t('创建时间'),
        value: <span>{RenderUtil.formatDate(item?.createTime,"YYYY-MM-DD HH:mm:ss")}</span>,
      },
    ],
    [+CompletedOrderOrderProcessEnum.SUB]: [
      {
        label: t('币种'),
        value: <span className={successColor}>{item?.coinName}</span>,
      },
      {
        label: t('金额'),
        value: <span>{RenderUtil.FormatAmount(item?.amount, 8)}</span>,
      },
      {
        label: t('手续费'),
        value: <span>{RenderUtil.FormatAmount(item?.fee, 8)}</span>,
      },
      {
        label: t('实际到账金额'),
        value: <span>{RenderUtil.FormatAmount(item?.actualAmount, 8)}</span>,
      },
      {
        label: t('进度'),
        value: (
          <>
            {item?.status === +CompletedOrderOrderStatusEnum.UnderReview && (
              <span className="text-left text-warningColor">{t('审核中')}</span>
            )}
            {item?.status === +CompletedOrderOrderStatusEnum.COMPLETED && (
              <span className={`text-left  ${successColor}`}>
                {t('已通过')}
              </span>
            )}
            {item?.status === +CompletedOrderOrderStatusEnum.DID_NOT_PASS && (
              <span className="text-left text-errorColor">{t('未通过')}</span>
            )}
            {item?.status === +CompletedOrderOrderStatusEnum.CANCELLED && (
              <span className="text-left text-foreground">{t('已取消')}</span>
            )}
          </>
        ),
      },
      {
        label: t('创建时间'),
        value: <span>{RenderUtil.formatDate(item?.createTime,"YYYY-MM-DD HH:mm:ss")}</span>,
      },
    ],
  };
  return (
    <div
      key={item}
      onClick={() => {
        clickOnTheCard && clickOnTheCard(item);
      }}
    >
      {/*展示的内容*/}
      {showCardMap[item?.cardType]?.map((item, index) => {
        return (
          <div key={index}>
            <div className="flex justify-between item?s-center  text-xs">
              <span className={`mb-2  flex-shrink-0 ${textColor}`}>
                {item?.label}
              </span>
              <div className={`text-right ${textColor}`}>{item?.value}</div>
            </div>
          </div>
        );
      })}
      {children}
    </div>
  );
};

interface IProps {
  item?: any;
  clickOnTheCard?: (data: any) => void;
  children?: ReactNode;
  isRight?: boolean;
  isReply?: boolean;
}
